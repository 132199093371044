@import '../../general.scss';

.github_section{
    border-radius: 6px;
    width: 25%;
    min-height: 390px;
    padding: 15px;
    background-color: $color_4;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.311);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .section_img{
        transition: 0.4s;
        width: 100px;
        height: 100px;
    }

    h3{
        transition: 0.4s;
        @extend %font1;
    }

    .description{
        transition: 0.4s;
        color: rgb(119, 119, 119);
        text-align: justify;
        margin: 0 10px 5px 10px;
    }

    .github_link_section{
        width: 100%;
        margin: 20px 20px 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .cap{
            @extend %font1; 
            font-size: 16px;
            font-weight: 600;
            color: $color_3;
            margin-left: 10px;
        }
        .link_section{
            margin-right: 10px;
            text-decoration: none;
            color: $color_2;
        }

        .link_section:hover{
            cursor: pointer;
            text-decoration: underline;
            color: $color_1;
        }
    }
    
    transition: 0.4s;
}

.github_section:hover{
    width: 27%;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.611);
    transition: 0.4s;
    .section_img{
        transition: 0.4s;
        width: 105px;
        height: 105px;
    }

    h3{
        transition: 0.4s;
        font-size: 22px;
    }

    .description{
        transition: 0.4s;
        font-size: 17.4px;
    }
}