@import "../general.scss";

.main_house {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  margin: 50px 15px;
  min-height: calc(100vh - 100px - 330px);

  .house {
    display: grid;
    box-sizing: border-box;
    border: 0px;
    border-radius: 5px;
    width: fit-content;
    height: 600px;
    box-shadow: 4px 8px 30px 3px rgba(0, 0, 0, 0.164);
    background-color: $color_1;
    padding: 5px 0;
  }

  .building_structure {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}

.margine{
  height: 32px;
}
