$color_1: #00c1d6;
$color_2: #000000;
$color_3: #c300ff;
$color_4: rgb(255, 255, 255);
$color_5: rgb(0, 81, 255);
$color_6: rgba(255, 0, 0, 0.37);
$color_sensor_1: rgb(197, 95, 0);
$color_sensor_2: rgb(218, 214, 0);
$color_sensor_3: rgb(40, 199, 0);
$color_sensor_4: rgb(99, 0, 54);

%font1 {
  font-family: "Archivo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

%text1{
    transition: 0.3s;
}

%text1:hover{
    transition: 0.3s;
}

%button1{
    width: 124px;
    height: 45px;
    padding: 0;
    color: $color_4;
    background: linear-gradient(90deg, rgba(0,193,214,1) 0%, rgba(195,0,255,1) 93%);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.164);
    border: none;
    border-radius: 5px;
    margin: 20px 12px 20px 12px;
    transition: 0.5s;
    a{
        background-color: rgba(0,193,214,1);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: $color_1;
        text-align: center;
        overflow: hidden;
        transition: 0.5s;
        p{
            font-family: 'Inter', sans-serif;
            font-style: light;
            font-weight: 500;
            line-height: 19px;
            font-size: 17px;
            transition: 0.4s;
        }
    }
}

%button1:hover{
    transition: 0.5s;
    width: 134px;
    height: 50px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.411);
    //animation: gradient 15s ease infinite;
    a{
        background-color: rgba(0,193,214,0);
        transition: 0.5s;
        p{
            font-size: 19px;
            transition: 0.4s;
        }
    }
}

%button2{
    width: 104px;
    height: 40px;
    font-size: 14px;
    color: $color_2;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.164);
    border: none;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-style: light;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    line-height: 19px;
    margin: 0;
    transition: 0.5s;
}

%button2:hover{
    transition: 0.5s;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.411);
    width: 134px;
    height: 45px;
    margin: 6px 6px 5px;
    font-size: 16px;
}
