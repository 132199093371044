@import "../../general.scss";

.lower_section {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  .ststus_show {
    margin: 25px 0 10px 40px;
    h3 {
      margin: 0;
    }
    p {
      text-align: center;
      margin: 20px 0;
      padding: 5px;
      border: 1px solid $color_1;
      border-radius: 3px;
      @extend %font1;
      font-weight: 800;
      color: $color_1;
    }
  }
  .button_section2 {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    .main_title_btn2 {
      @extend %button1;
      width: 170px;
      margin: 30px 12px 0 0;
    }

    .main_title_btn2:hover {
      width: 185px;
      margin: 25px 12px 0 0;
    }
    .btn-inactive {
      @extend %button2;
      background-color: #dadada;
      width: 170px;
      margin: 30px 12px 0 0;

      font-size: 16px;
    }
    .btn-inactive:hover {
      width: 170px;
      height: 40px;
      margin: 30px 12px 0 0;

      font-size: 16px;
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.164);
    }
  }
}
