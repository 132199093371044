@import "../../../general.scss";
.sensor-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  .btn-deactivate {
    @extend %button2;
    background-color: $color_4;
    color: $color_2;
  }

  .btn-deactivate:hover {
    width: 104px;
    height: 40px;
    font-size: 14px;
    margin: 0;
  }
  .active {
    background-color: rgb(255, 46, 46);
  }
}
