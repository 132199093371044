@import "../../general.scss";

.sensors-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  justify-content: start;
  background-color: $color_1;
  padding: 20px 40px;
  height: 460px;
  width: 110%;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 4px 8px 30px 3px rgba(0, 0, 0, 0.164);

  .sensors-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .btn-close {
      @extend %button2;
      color: $color_2;
      
    }

    .btn-close:hover{
      width: 104px;
      height: 40px;
      font-size: 14px;
      margin: 0;
    }
  }
}
