@import "../../general.scss";

.new-building {
  margin-top: 20px;
  margin-left: 10px;
  padding: 20px 10px 15px 10px;
  width: 100%;
  height: 130px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.164);

  .new-building-input {
    padding-left: 16px;
    width: 30%;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    border: 1.5px solid #9b9a9a;
    border-radius: 4px;
    outline: none;
    margin: 0 8px;
  }
  .new-building-button-container {
    width: 150px;
  }
  .new-building-btn {
    @extend %button1;
    margin: 5px;
    a{
      p{
        font-size: 14px;
      }
    }
  }
  .btn-inactive {
    @extend %button2;
    background-color: #dadada;
    width: 170px;
    margin: 0 0 0 12px;

    font-size: 16px;
  }
  .btn-inactive:hover {
    width: 170px;
    height: 40px;
    margin: 0 0 0 12px;

    font-size: 16px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.164);
  }
  .new-building-btn:hover {
    a{
      p{
        font-size: 16px;
      }
    }
  }
}

.hidden{
  display: none;
}
