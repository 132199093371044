@import '../../../components/general.scss';

.main_title_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main_caption{
        z-index: 2;
        @extend %font1;
        font-size: 36px;
        margin: 40px 0 5px 0;
    }
    .description{
        z-index: 3;
        letter-spacing: 1px;
        @extend %font1;
        font-weight: 200;
    }
    .decoration{
        z-index: 1;
        position: absolute;
        background-color: $color_1;
        top: 205px;
        justify-self: center;
        width: 80px;
        height: 0px;
        border-radius: 50px;
        box-shadow: 0px 0px 200px 40px rgb(0, 193, 214);
    }

    .button_section1{
        z-index: 4;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100px;
        margin-bottom: 50px;
    }
    .main_title_btn{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @extend %button1;  
    }

    .non_active{
        display:none;
    }
}