@import "../../../../general.scss";

.room {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  border: $color_5 solid 1px;
  background-color: $color_4;
  .gif{
    margin: 8px;
    background-color: white;
  }
}
.room:hover {
  background-color: #dadada;
}
.alarm {
  animation-name: active-sensor;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes active-sensor {
  0% {
    border: $color_5 solid 1px;
  }
  50% {
    border: red solid 5px;
  }
  100% {
    border: $color_5 solid 1px;
  }
}

.hidden{
  display: none;
}