@import "../../general.scss";
.floor-outline {
  min-width: 55%;
  height: 800px;

  padding: 10px;
  background-color: $color_1;
  border-radius: 10px;

  box-sizing: border-box;

  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}
