@import '../../components/general.scss';

.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: calc(100vh - 100px - 320px);
}

.second_section{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
}