@import '../../../components/general.scss';

// DIALOG FOR LOGIN //

#form_block {  
    margin-top: 100px; 
    display: none;
    z-index: 10;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 0 1em rgb(0 0 0 / .3);
    @extend %font1;
}

#form_block_active {  
    display: flex;
    position: fixed;
    top: 30%;
    justify-self: center;
    width: 240px; 
    z-index: 10;
    display: block;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 50px 600px 200px rgba(0, 0, 0, 0.466);
    padding: 15px 22px 20px 22px;
    @extend %font1;
}

.modal::backdrop {
    background: rgb(0 0 0 / 0.4);
}

form {
    @extend %font1;
    margin: 0px;
    display: grid;
    div{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    select{
        border-radius: 5px;
        border: 1px solid #ccc; 
        padding-top: 3px;
        cursor: pointer;
    }
    select:hover{
        background-color: #e0e0e0;
    }
    label{
        margin-left: 10px;
        font-size: 14px;
        padding: 0 10px 0 4px;
        position: absolute;
        font-family: 'Montserrat', sans-serif;
        color: #9b9a9a;
        z-index: 1;
    }

    label::after{
        height: 56%;
        width: 68%;
        padding: 0 10px 0 10px;
        content: '';
        position: absolute;
        bottom:0;
        left: 0;
        background-color: #ffffff;
        z-index: -1;
    }
    .namelabel{  //captions placement
        top: 71px;
    }
    .passwordlabel{
        top: 153px;
    }
}


.dialog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
        margin: 8px 0;
    }
    margin-bottom: 10px;
}

.dialog_submit{
    margin-top: 20px;
    text-align: right;
}


.name{
    padding-left: 16px;
    width: 92%;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    border: 1.5px solid #9b9a9a;
    border-radius: 4px;
    margin: 20px 0 0 0;
    outline: none;
}

.name:-webkit-autofill {
     -webkit-box-shadow: inset 0 0 0 55px #fff!important;
    box-shadow: inset 0 0 0 55px #fff!important;
}

.name :placeholder-shown{
    color: #dddddd;
    letter-spacing: 1px;
}

#submitData{
    @extend %button2;
    color: $color_4;
    background: linear-gradient(90deg, rgba(0,193,214,1) 0%, rgba(0,193,214,1) 93%);
}

#submitData:hover{
    width: 104px;
    height: 40px;
    font-size: 14px;
    margin: 0;
}

#cancel_submit{
    @extend %button2; 
    color: $color_4; 
    background: linear-gradient(90deg, rgba(0,193,214,1) 0%, rgba(0,193,214,1) 93%);
}

#cancel_submit:hover{
    width: 104px;
    height: 40px;
    font-size: 14px;
    margin: 0;
}

.close_modal{
    width: 27px;
    height: 25px;
    border-radius: 4px;
    background-color: $color_1;
    border: 0px solid $color_1;
    transition: 0.4s;
    i{
        color: $color_4;
    }
}

.close_modal:hover{
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.164);
    transition: 0.4s;
}