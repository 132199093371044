@import '../general.scss';
footer{
    margin-top: 100px;
    @extend %font1;
    .footer_main_section{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 15px;
    }
    .footer_logo_section{
        margin-top: 17px;
        width: 230px;
        .part0_footer{
            font-weight: 300;
            padding-left: 20px;
        }
    }
}
.footer_contact_img{
    display: flex;
    width: 210px;
    height: 60px;
}

.footer_section{
    display: flex;
    flex-direction: column;
    justify-content: start;
    style
    h3{
        letter-spacing: 1px;
    }
    .part3_footer{
        margin: 10px 0 10px 0;
        .navigation{
            cursor: pointer;
            text-decoration:none;
            list-style-type: none;
            color: $color_2;
        }
        .navigation:hover{
            color: $color_1;
        }
    }
}

.footer_rights{
    font-size: 14px;
    display: flex;
    align-self: center;
    justify-self: center;
    font-weight: 200;
}

.documentation_page{
    min-height: calc(100vh - 100px - 320px);
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    .section_img{
        margin: 20px 0;
        width: 60%;
        height: auto;
    }
}

.guide_page{
    min-height: calc(100vh - 100px - 320px);
    .guide_section{
        list-style-type: none;
        padding: 10px 15px;
        margin: 40px 0;
        border-radius: 8px;
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.164);
        .guide_caption_underline{
            width: 50px;
            height: 5px;
            background-color: $color_1;
            margin-left: 7px;
        }
    }
}

.github_page{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: calc(100vh - 100px - 320px);
}
