@import "../general.scss";
.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 20%;
  width: 51%;
  cursor: pointer;
  .pages {
    color: black;
    @extend %font1;
    font-weight: 600;
    transition: 0.3s;
    text-decoration: none;
  }
  .pages:hover {
    transition: 0.3s;
    color: $color_1;
    text-shadow: 1px 5px 8px #c9c9c9;
  }
  .pages-inactive {
    color: grey;
    @extend %font1;
    font-weight: 600;
    transition: 0.3s;
    text-decoration: none;
  }
  .active {
    @extend %font1;
    font-weight: 600;
    text-decoration: none;
    color: $color_1;
    text-shadow: 1px 5px 8px #c9c9c9;
  }
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  .logo_section_1 {
    .logo_img {
      display: flex;
      width: 210px;
      height: 60px;
    }
  }
}
