@import "../../../components/general.scss";

.second_section_simulations {
  width: 50vw;
  min-height: 470px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  h3 {
    padding-left: 12px;
  }

    .button_section2{
        min-height: 180px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        align-self: center;
    }
    .button_section3{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        align-self: flex-end;
        .main_title_btn2{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 194px;
            height: 65px; 
            font-size: 20px;
            .navigation{
                text-decoration: none;
                color: $color_4;
            }
            p{
                margin: 0 20px;
            }
        }
    
        .main_title_btn2:hover{ 
            width: 244px;
            height: 70px; 
            font-size: 22px;
            p{
                margin: 0 20px;
            }
        }
    }
    .main_title_btn2{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @extend %button1; 
        width: 144px;
        height: 55px; 
        margin: 0 10px 20px;
        .navigation{
            text-decoration: none;
            color: $color_4;
        }
        p{
            margin: 0 5px;
        }
    }

    .btn-inactive{
      display: none;
    }

  @media (min-width: 700px) {
    .main_title_btn2 {
      margin: 0 calc(3vw) 20px;
    }
  }

  @media (min-width: 1400px) {
    .main_title_btn2 {
      margin: 0 calc(7vw) 20px calc(7vw);
    }
  }

  .main_title_btn2:hover {
    width: 154px;
    height: 60px;
  }
}
